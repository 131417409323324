import { GoHome } from "react-icons/go";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { BsCart3, BsTelephone } from "react-icons/bs";

import { FaRegUserCircle } from "react-icons/fa";

const MobileNav = () => {
  const { cartItems: product } = useSelector((state) => state.cart);
  const { userAndToken: userData } = useSelector((state) => state.auth);
  const { items: adminInfo } = useSelector((state) => state.information);
  return (
    <div className="bg-[#FBDD01] border-t border-gray-400">
      <div className="container mx-auto py-4 flex items-center  justify-between px-5 md:px-0 ">
        <Link to="/">
          <GoHome className="text-2xl text-[#141101]" />
        </Link>
        <Link to="/addtocart" className=" relative group">
          <BsCart3 className="text-2xl text-[#141101]" />
          <span className=" absolute -top-2 text-sm right-2 text-slate-50 bg-red-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
            {product.length}
          </span>
        </Link>
        <a href={`tel:${adminInfo.phone}`}>
          <BsTelephone className="text-2xl text-[#141101]" />
        </a>

        <div>
          {userData && (
            <Link
              to={`/user`}
              className="flex items-center  justify-center gap-2"
            >
              {!userData?.user?.avatar && (
                <span className=" w-10 h-10 overflow-hidden rounded-full border-2 border-[#D89307]">
                  {/* <FaRegUserCircle className="text-2xl" /> */}
                  <img
                    src="/default_image.jpeg"
                    alt="default avatar"
                    className="w-full h-full object-cover"
                  />
                </span>
              )}
              {userData?.user?.avatar && (
                <span className=" w-12 h-12  overflow-hidden rounded-full border-2 border-[#D89307]">
                  {/* <FaRegUserCircle className="text-2xl" /> */}
                  <img
                    src={`${process.env.REACT_APP_URL}/uploads/users/${userData?.user?.avatar}`}
                    alt={`${userData?.user?.name}`}
                    className="w-full h-full object-cover"
                  />
                </span>
              )}
            </Link>
          )}
          {!userData && (
            <Link to={"/login"}>
              <FaRegUserCircle className="text-2xl text-[#141101]" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default MobileNav;
