import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

const initialState = {
  items: {},
  status: null,
};

// data fatching

export const infoFatching = createAsyncThunk("info/infoFatching", async () => {
  const res = await axios.get(`${process.env.REACT_APP_URL}/api-information`);

  return res.data;
});

export const infoSlice = createSlice({
  name: "info/infoFatching",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(infoFatching.pending, (state) => {
      state.status = "loading...";
    });
    builder.addCase(infoFatching.fulfilled, (state, action) => {
      state.status = "";
      state.items = action.payload;
    });
    builder.addCase(infoFatching.rejected, (state) => {
      state.status = "Something Went Wrong";
    });
  },
});

export default infoSlice.reducer;
