import { useSelector } from "react-redux";

const Banner = () => {
  const { items: banner } = useSelector((state) => state.bannerSlider);

  return (
    <div className="container mx-auto my-10">
      <div className="grid md:grid-cols-2  gap-5">
        {banner.slice(0, 2).map((img) => (
          <div
            key={img.id}
            className="banner-image rounded-md overflow-hidden "
          >
            <img
              src={`${process.env.REACT_APP_URL}/public/uploads/slider/${img?.slider}`}
              alt="banner"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Banner;
