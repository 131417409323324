import { useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCall } from "react-icons/md";
import { FiSearch } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";
import { FiMenu } from "react-icons/fi";
// import { FaRegUserCircle } from "react-icons/fa";

import { useSelector } from "react-redux";
import { FaRegUserCircle } from "react-icons/fa";

const Navbar = ({ handleMenu }) => {
  const [searchItem, setSearchItem] = useState("");
  const inputField = useRef(null);
  const { items: data } = useSelector((state) => state.category);
  const { items: subCategory } = useSelector((state) => state.subCategories);
  const { cartItems: product } = useSelector((state) => state.cart);

  const { items: logo } = useSelector((state) => state.logo);

  const { userAndToken: userData } = useSelector((state) => state.auth);
  const { items: adminInfo } = useSelector((state) => state.information);
  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#292928" : null,
      Font: isActive ? "font-bold" : null,
    };
  };

  const handleSearch = (e) => {
    e.preventDefault();

    //form reset
    setSearchItem("");
    inputField.current.blur();
  };

  const getSubcategoriesByCategoryId = (categoryId) => {
    return subCategory?.filter(
      (subcategory) => +subcategory.category_id === categoryId
    );
  };

  return (
    <>
      <div className="bg-[#FBDD01] py-2 px-4 md:px-0 hidden md:block text-[#141101] font-normal text-sm">
        {/* upper nav */}
        <div className="upper-nav container mx-auto  flex items-center justify-between  ">
          <div className="upper-nav-right flex items-center justify-between gap-10">
            <Link>Track Order</Link>
          </div>
          <div className="flex gap-5">
            <div className="flex gap-1 items-center ">
              <MdOutlineCall />
              <p>Help line : {adminInfo.phone}</p>
            </div>
            <Link>
              Compare<span>(1)</span>
            </Link>
            <Link>
              wishlist<span>(1)</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="sticky px-4 md:px-0 top-0 left-0  right-0 z-[9999] shadow-md bg-white">
        {" "}
        {/* middle nav */}
        <div className="bg-white/75 py-5 px-5 xl:px-0 ">
          <div className="container mx-auto flex  items-center justify-between">
            <button className="md:hidden block duration-700 text-[#141101]">
              <FiMenu onClick={handleMenu} className="text-[20px]  " />
            </button>
            <Link to={"/"} className="w-24 h-24 rounded-full overflow-hidden ">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="max-h-full max-w-full w-auto  h-auto"
                alt={`${logo?.name}`}
              />
            </Link>
            {/* search button */}
            <div className="hidden md:block">
              <form
                onSubmit={handleSearch}
                className="relative flex items-center justify-center"
              >
                <input
                  ref={inputField}
                  type="text"
                  value={searchItem}
                  placeholder="Search Items......"
                  onChange={(e) => setSearchItem(e.target.value)}
                  className=" bg-transparent border p-3 px-8  w-[20rem] rounded-full focus:outline-none "
                />
                <button className="absolute  rounded-full font-extrabold text-black/70 py-2 px-4 top-[10px] right-2">
                  <FiSearch />
                </button>
              </form>
            </div>

            <div className=" text-[#141101]  text-[1rem] flex justify-center items-center gap-5 duration-1000">
              {!userData && (
                <>
                  <Link
                    to={`/login`}
                    className=" hover:underline  underline-offset-2 duration-300 "
                  >
                    <FaRegUserCircle className="text-2xl text-[#141101]" />
                  </Link>
                  {/* <span className="text-slate-400/40">or</span>
                  <Link
                    to={`/register`}
                    className="hover:underline underline-offset-2 duration-300"
                  >
                    Registration
                  </Link> */}
                </>
              )}

              {userData && (
                <Link
                  to={`/user`}
                  className="flex items-center  justify-center gap-2"
                >
                  {!userData?.user?.avatar && (
                    <span className=" w-10 h-10 overflow-hidden rounded-full border-2 border-[#FBDD01]">
                      {/* <FaRegUserCircle className="text-2xl" /> */}
                      <img
                        src="/default_image.jpeg"
                        alt="default avatar"
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  {userData?.user?.avatar && (
                    <span className=" w-12 h-12  overflow-hidden rounded-full border-2 border-[#FBDD01]">
                      {/* <FaRegUserCircle className="text-2xl" /> */}
                      <img
                        src={`${process.env.REACT_APP_URL}/uploads/users/${userData?.user?.avatar}`}
                        alt={`${userData?.user?.name}`}
                        className="w-full h-full object-cover"
                      />
                    </span>
                  )}
                  <p className="text-[0.7rem] capitalize md:block hidden  md:text-[1.1rem]">
                    {userData?.user?.name}
                  </p>
                </Link>
              )}
              <Link to="/addtocart" className="hidden md:block  relative group">
                <BsCart3 className="text-2xl text-[#141101]" />
                <span className=" absolute bottom-3 text-sm left-4 bg-[#FBDD01] h-5 w-5 flex items-center justify-center rounded-full text-[#141101] group-hover:bg-sky-500 duration-300">
                  {product.length}
                </span>
              </Link>
              {/* <Link to="/addtocart" className="  relative group">
                <BsCart3 className=" text-[#49280c]" />
                <span className=" absolute bottom-2 text-sm left-2 text-slate-50 bg-rose-500 h-5 w-5 flex items-center justify-center rounded-full group-hover:bg-sky-500 duration-300">
                  {product.length}
                </span>
              </Link> */}
            </div>
          </div>
        </div>
        {/* lower nav */}
        <div className={`bg-[#FBDD01] hidden md:block`}>
          <div className="container mx-auto">
            <div className="flex justify-center">
              <ul className="flex text-[0.9rem] text-[#141101] lg:text-[1rem] gap-5 py-2 justify-between items-center">
                <li>
                  <NavLink
                    style={navActive}
                    end
                    to={"/"}
                    className="hover:text-[#141101] duration-300"
                  >
                    Home
                  </NavLink>
                </li>
                {data?.slice(0, 5).map((navItem) => (
                  <li key={navItem?.id} className="relative group">
                    <NavLink
                      className="relative group"
                      style={navActive}
                      to={`/categorypage/category/${navItem.id}`}
                      key={navItem?.id}
                    >
                      <span className="hover:text-[#141101] duration-300  ">
                        {navItem?.name}
                      </span>
                      <ul className="absolute hidden group-hover:block group-hover:ease-in-out duration-300 bg-[#c72c67] w-48 top-5">
                        {getSubcategoriesByCategoryId(navItem?.id).map(
                          (subCat) => (
                            <li
                              key={subCat.id}
                              className="px-5 py-1 hover:bg-[#141101] cursor-pointer"
                            >
                              <Link to={`/subcategory/${subCat?.id}`}>
                                {subCat.name}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
