import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";

import LandingNavbar from "../components/LandingNavbar";
import LandingFooter from "../components/LandingFooter";

const LandingLayout = () => {
  return (
    <div className=" bg-[#F2F3F8] ">
      <div className="min-h-screen">
        <LandingNavbar />
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>
      </div>
      <LandingFooter />
    </div>
  );
};

export default LandingLayout;
