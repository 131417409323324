import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AddToCartCom from "../components/AddToCartCom";
import CartButton from "../components/CardButton";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../components/ScrollToTop";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileNav from "../components/MobileNav";
import SideBar from "../components/SideBar";

const HomeLayout = () => {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  // const [loading, setLoading] = useState(false);

  const [state, setState] = useState(false);

  const handleAddToCart = () => {
    setState(!state);
  };
  const [sideNav, setSideNav] = useState(false);

  const handleMenu = () => {
    document.body.style.top = `-${window.scrollY}px`;
    document.body.style.position = "fixed";

    document.body.style.left = 0;
    document.body.style.right = 0;
    document.body.style.bottom = 0;
    setSideNav(() => !sideNav);
  };
  const handleClose = () => {
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    setSideNav(() => !sideNav);
  };
  return (
    <>
      {sideNav && (
        <>
          <div
            onClick={handleClose}
            className="bg-black/40   z-[9999]  fixed top-0 left-0 right-0 bottom-0 w-full h-full"
          ></div>
          <div className="flex justify-center items-center min-w-full">
            <SideBar handleClose={handleClose} />
          </div>
        </>
      )}
      <div className="min-h-screen relative ">
        <Navbar handleMenu={handleMenu} />
        <ToastContainer />
        <ScrollToTop>
          <Outlet />
        </ScrollToTop>

        <button
          onClick={handleAddToCart}
          className="fixed top-[45%] right-0 z-[9999]"
        >
          <CartButton data={data} cartTotalAmount={cartTotalAmount} />
        </button>
        {state && <AddToCartCom state={state} setState={setState} />}
        <div className="fixed z-[99999] bottom-0  md:hidden block w-full ">
          <MobileNav />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HomeLayout;
