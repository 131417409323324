// import React, { useCallback, useEffect, useState } from "react";

// import axios from "axios";
// import { useNavigate, useParams } from "react-router-dom";
// import { AiOutlineMinus } from "react-icons/ai";

// import { BsPlus } from "react-icons/bs";
// import RelatedProduct from "../components/RelatedProduct";
// import {
//   addToSingleCart,
//   removeAllFromCart,
// } from "../features/products/cartSlice";
// import { useDispatch, useSelector } from "react-redux";
// import SectionTitle from "../components/SectionTitle";

// import { toast } from "react-toastify";
// import { Parser } from "html-to-react";
// import { specificAttributeFetching } from "../features/products/specificAttributeSlice";
// const ProductDetails = () => {
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   const htmlParser = new Parser();

//   const [cartQuantity, setCartQuantity] = useState(1);
//   const [singleProduct, setSingleProduct] = useState({});
//   const [thumbImage, setThumbImage] = useState([]);
//   const { items: attribute } = useSelector((state) => state.attribute);
//   const [option, setOption] = useState(null);

//   const setSelectedOption = (e) => {
//     const option = attribute?.find(
//       (option) => option?.variant === e.target.value
//     );

//     setOption(option);
//   };

//   useEffect(() => {
//     dispatch(specificAttributeFetching(id));
//   }, [dispatch, id]);
//   const navigate = useNavigate();
//   useEffect(() => {
//     const singleProduct = async () => {
//       const res = await axios.get(
//         `${process.env.REACT_APP_URL}/api-products/${id}`
//       );
//       return setSingleProduct(res.data);
//     };
//     singleProduct();
//   }, [id]);

//   useEffect(() => {
//     const imageThambs = async () => {
//       const res = await axios.get(
//         `${process.env.REACT_APP_URL}/api-thumb/${id}`
//       );

//       return setThumbImage(res.data);
//     };
//     imageThambs();
//   }, [id]);

//   const handleDecrease = useCallback(() => {
//     setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
//     cartQuantity > 1 &&
//       toast.warn("Quantity Decreased", {
//         position: "bottom-left",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//   }, [cartQuantity]);

//   const handleIncrease = useCallback(() => {
//     setCartQuantity(
//       cartQuantity >= singleProduct?.product?.current_stock
//         ? singleProduct?.product?.current_stock
//         : (prev) => prev + 1
//     );
//     singleProduct?.product?.current_stock > cartQuantity &&
//       toast.warn("Quantity Increased", {
//         position: "bottom-left",
//         autoClose: 5000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
//       });
//   }, [cartQuantity, singleProduct?.product?.current_stock]);
//   // const handleAddToCart = () => {
//   //   dispatch(
//   //     addToSingleCart({
//   //       ...singleProduct.product,
//   //       cartQuantity,
//   //       variantPrice:
//   //         singleProduct?.product?.after_discount > 0
//   //           ? +singleProduct?.product?.after_discount
//   //           : +singleProduct?.product?.unit_price,
//   //     })
//   //   );
//   // };
//   // const handleBuyNow = () => {
//   //   dispatch(removeAllFromCart());
//   //   dispatch(
//   //     addToSingleCart({
//   //       ...singleProduct.product,
//   //       cartQuantity,
//   //       colorAtr: null,
//   //       sizeAtr: null,
//   //       variantPrice:
//   //         singleProduct?.product?.after_discount > 0
//   //           ? +singleProduct?.product?.after_discount
//   //           : +singleProduct?.product?.unit_price,
//   //     })
//   //   );
//   //   navigate("/checkout");
//   // };
//   const handleAddToCart = (action) => {
//     switch (action) {
//       case "add_to_cart":
//         if (attribute?.length > 0) {
//           if (option) {
//             if (+option?.qty >= cartQuantity) {
//               dispatch(
//                 addToSingleCart({
//                   ...singleProduct.product,
//                   cartQuantity,
//                   option,
//                   colorAtr: option?.variant ? option?.variant : null,
//                   variantPrice:
//                     option?.discount_price > 0
//                       ? +option?.discount_price
//                       : +option?.price,
//                 })
//               );
//             } else {
//               toast.warn("Not Enough Product In Stock", {
//                 position: "bottom-left",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "light",
//               });
//             }
//           }
//         } else {
//           if (!option) {
//             // dispatch(removeAllFromCart());
//             if (+singleProduct?.product?.current_stock >= cartQuantity) {
//               dispatch(
//                 addToSingleCart({
//                   ...singleProduct.product,
//                   cartQuantity,
//                   colorAtr: null,
//                   variantPrice:
//                     singleProduct?.product?.after_discount > 0
//                       ? +singleProduct?.product?.after_discount
//                       : +singleProduct?.product?.unit_price,
//                 })
//               );
//             } else {
//               toast.warn("Not Enough Product In Stock", {
//                 position: "bottom-left",
//                 autoClose: 5000,
//                 hideProgressBar: false,
//                 closeOnClick: true,
//                 pauseOnHover: true,
//                 draggable: true,
//                 progress: undefined,
//                 theme: "light",
//               });
//             }
//           }
//         }

//         break;

//       case "buy_now":
//         if (attribute?.length > 0) {
//           if (+option?.qty >= cartQuantity) {
//             if (option) {
//               dispatch(removeAllFromCart());
//               dispatch(
//                 addToSingleCart({
//                   ...singleProduct.product,
//                   cartQuantity,
//                   option,
//                   colorAtr: option?.variant ? option?.variant : null,
//                   variantPrice:
//                     option.discount_price > 0
//                       ? +option?.discount_price
//                       : +option?.price,
//                 })
//               );
//               navigate("/checkout");
//             }
//           }
//         } else {
//           if (!option) {
//             if (+singleProduct?.product?.current_stock >= cartQuantity) {
//               dispatch(removeAllFromCart());
//               dispatch(
//                 addToSingleCart({
//                   ...singleProduct.product,
//                   cartQuantity,
//                   colorAtr: null,
//                   variantPrice:
//                     singleProduct?.product?.after_discount > 0
//                       ? +singleProduct?.product?.after_discount
//                       : +singleProduct?.product?.unit_price,
//                 })
//               );
//               navigate("/checkout");
//             }
//           }
//         }
//         // if (option) {
//         //   dispatch(removeAllFromCart());
//         //   dispatch(
//         //     addToSingleCart({
//         //       ...singleProduct.product,
//         //       cartQuantity,
//         //       colorAtr: option.variant,
//         //       variantPrice:
//         //         option.discount_price > 0
//         //           ? +option.discount_price
//         //           : +option.price,
//         //     })
//         //   );
//         //   navigate("/checkout");
//         // }

//         break;
//       default:
//         break;
//     }
//   };
//   return (
//     <>
//       {singleProduct?.product && (
//         <div className="container mx-auto md:my-10 min-h-screen ">
//           <div className="flex flex-col lg:flex-row md:px-10 gap-[2rem] lg:gap-[3rem] p-3 items-center lg:items-start">
//             {/* Left site */}
//             <ProductImageCarasol
//               // key={singleProduct.id}
//               thumbImage={thumbImage}
//               singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
//             />

//             {/* Right site */}
//             {singleProduct?.status && (
//               <div className="right flex lg:w-[40rem] w-full flex-col justify-start gap-3">
//                 <form onSubmit={(e) => e.preventDefault()}>
//                   <div className="details flex md:flex-row flex-col  gap-8 text-md mt-[12px]">
//                     <div className="flex flex-col gap-5">
//                       <div className="md:text-4xl sm:text-3xl text-black font-semibold">
//                         {singleProduct?.product?.name}
//                       </div>
//                       <div className="flex gap-5">
//                         {/* {singleProduct?.product?.unit_price ===
//                         singleProduct?.product?.after_discount &&
//                       singleProduct?.product?.after_discount > 0 ? (
//                         <div className="font-medium text-2xl">
//                           ৳ {singleProduct?.product?.unit_price}
//                         </div>
//                       ) : (
//                         <div className="flex gap-5">
//                           <span className="font-semibold line-through text-[#FA5303] decoration-200">
//                             ৳ {singleProduct?.product?.unit_price}
//                           </span>
//                           <span className="font-medium text-2xl">
//                             ৳ {+singleProduct?.product?.after_discount}
//                           </span>
//                         </div>
//                       )} */}

//                         {!option && singleProduct?.product && (
//                           <div className="flex gap-5">
//                             <span className="font-semibold line-through text-[#FBDD01] decoration-200">
//                               ৳ {singleProduct?.product?.unit_price}
//                             </span>
//                             <div className="font-medium text-2xl">
//                               {singleProduct?.product &&
//                               singleProduct?.product?.after_discount > 0 ? (
//                                 <>
//                                   <span>
//                                     ৳ {singleProduct?.product?.after_discount}
//                                   </span>
//                                 </>
//                               ) : (
//                                 <>
//                                   {singleProduct?.product && (
//                                     <span>
//                                       ৳
//                                       {singleProduct?.product?.unit_price *
//                                         cartQuantity}
//                                     </span>
//                                   )}
//                                 </>
//                               )}
//                             </div>
//                           </div>
//                         )}
//                         {option && (
//                           <div className="flex gap-5">
//                             <span className="font-semibold line-through text-[#FBDD01] decoration-200">
//                               ৳{option?.price}
//                             </span>
//                             <div className="font-medium text-2xl">
//                               {singleProduct?.product && (
//                                 <span> ৳{+option?.discount_price}</span>
//                               )}
//                             </div>
//                           </div>
//                         )}

//                         {/* <div className="font-medium text-2xl">
//                   {singleProduct?.product?.after_discount > 0 ? (
//                     <>৳ {+singleProduct?.product?.after_discount}</>
//                   ) : (
//                     <>৳ {+singleProduct?.product?.unit_price}</>
//                   )}
//                 </div> */}
//                       </div>

//                       {attribute.length > 0 && (
//                         <div className="form-control flex flex-col my-3">
//                           {/* <label>কাস্টম অপশন</label> */}
//                           <select
//                             required
//                             onChange={setSelectedOption}
//                             className="px-3 py-3 mb-4 w-36 rounded-md outline-none border focus:border-[#FBDD01] duration-300"
//                           >
//                             <option value={""}>Select Size</option>
//                             {attribute?.map((option) => (
//                               <option
//                                 key={attribute?.length}
//                                 value={option?.variant}
//                               >
//                                 {option?.variant}
//                               </option>
//                             ))}
//                           </select>
//                         </div>
//                       )}
//                       <div className="flex gap-3">
//                         <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium  uppercase items-center">
//                           Quantity :
//                         </span>
//                         <div className="flex items-center gap-4 ">
//                           <button
//                             className=" bg-slate-500/30 rounded-full flex justify-center items-center font-semibold text-xs h-5 w-5 hover:bg-[#218698] hover:text-white duration-500"
//                             onClick={() =>
//                               handleDecrease(singleProduct?.product)
//                             }
//                           >
//                             <AiOutlineMinus />
//                           </button>
//                           <span>{cartQuantity}</span>
//                           <button
//                             className=" bg-slate-500/30 h-5 w-5  rounded-full flex justify-center items-center hover:bg-[#218698] hover:text-white duration-500"
//                             onClick={() =>
//                               handleIncrease(singleProduct?.product)
//                             }
//                           >
//                             <BsPlus />
//                           </button>
//                         </div>
//                       </div>
//                       <div className="total-price flex gap-3 items-center ">
//                         <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
//                           Total Stock :
//                         </span>
//                         <span className="inline-block text-2xl font-semibold text-[#FBDD01] ">
//                           {singleProduct?.product?.current_stock}
//                         </span>
//                       </div>
//                       {/* <div className="total-price flex gap-3 items-center ">
//                       <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
//                         Total Price :
//                       </span>
//                       <span className="inline-block text-2xl font-semibold text-black ">
//                         {singleProduct?.product?.discount > 0 ? (
//                           <>
//                             ৳ {singleProduct?.product?.discount * cartQuantity}
//                           </>
//                         ) : (
//                           <>
//                             ৳{singleProduct?.product?.unit_price * cartQuantity}
//                           </>
//                         )}
//                       </span>
//                     </div> */}
//                     </div>
//                   </div>

//                   {/* <div className="flex mt-8 items-center font-semibold gap-5">
//                     <button
//                       onClick={() => handleAddToCart("add_to_cart")}
//                       // disabled={option === null}
//                       className={`border-[1px] ${
//                         option === null ? "" : ""
//                       } border-black text-black  text-slate-50 hover:bg-[#000] hover:text-white duration-300 w-full py-1 rounded-full`}
//                       type="submit"
//                     >
//                       Add to Cart
//                     </button>

//                     <button
//                       onClick={() => handleAddToCart("buy_now")}
//                       className="border text-center  bg-black hover:text-slate-50 ] text-white duration-300 w-full  rounded-full py-1 "
//                       type="submit"
//                     >
//                       Buy Now
//                     </button>
//                   </div> */}

//                   {attribute?.length > 0 && +option?.qty === 0 ? (
//                     <p className="text-3xl text-red-400">Out Of Stock</p>
//                   ) : (
//                     <div className="flex mt-8 items-center font-semibold gap-5">
//                       <button
//                         onClick={() => handleAddToCart("add_to_cart")}
//                         // disabled={option === null}
//                         className={`border-[1px] ${
//                           option === null ? "" : ""
//                         } border-[#FBDD01] text-[#000]   hover:bg-[#FBDD01] hover:text-[#141101] duration-300 w-full py-1 rounded-full`}
//                         type="submit"
//                       >
//                         Add to Cart
//                       </button>

//                       <button
//                         onClick={() => handleAddToCart("buy_now")}
//                         className="border text-center  bg-[#FBDD01] hover:text-slate-50 ] text-[#141101] duration-300 w-full  rounded-full py-1 "
//                         type="submit"
//                       >
//                         Buy Now
//                       </button>
//                     </div>
//                   )}
//                 </form>
//                 {singleProduct?.product?.video_link ? (
//                   <div className="overflow-hidden ">
//                     <iframe
//                       width="600"
//                       height="360"
//                       src={`https://www.youtube.com/embed/${
//                         singleProduct?.product?.video_link?.split("=")[1]
//                       }`}
//                       title="YouTube video player"
//                       frameborder="0"
//                       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                       allowfullscreen
//                     ></iframe>
//                   </div>
//                 ) : (
//                   <div></div>
//                 )}
//               </div>
//             )}
//           </div>
//           <div className="px-3 mb-20 md:mb-15  md:px-0 pb-12">
//             <SectionTitle title={"Description"} />
//             <div className="mb-10">
//               {singleProduct?.product?.description ? (
//                 htmlParser.parse(singleProduct?.product?.description)
//               ) : (
//                 <p>No Description Added</p>
//               )}
//             </div>

//             <RelatedProduct singleProduct={singleProduct} />
//           </div>
//         </div>
//       )}
//     </>
//   );
// };

// const ProductImageCarasol = ({ thumbImage, singleProduct }) => {
//   const [mainImage, setMainImage] = useState("");
//   const [zoomLevel, setZoomLevel] = useState(1);
//   const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
//   const [selectedThumbIndex, setSelectedThumbIndex] = useState(0);

//   useEffect(() => {
//     if (singleProduct) {
//       setMainImage(singleProduct);
//     }
//   }, [singleProduct]);

//   const handleMouseEnter = () => {
//     setZoomLevel(2);
//   };

//   const handleMouseLeave = () => {
//     setZoomLevel(1);
//     setZoomPosition({ x: 0, y: 0 });
//   };

//   const handleMouseMove = (e) => {
//     const { left, top, width, height } =
//       e.currentTarget.getBoundingClientRect();
//     const x = ((e.clientX - left) / width) * 100;
//     const y = ((e.clientY - top) / height) * 100;
//     setZoomPosition({ x, y });
//   };

//   const handleThumbnailClick = (index) => {
//     setSelectedThumbIndex(index);
//     setMainImage(
//       `${process.env.REACT_APP_URL}/uploads/product/${thumbImage[index]?.icon}`
//     );
//   };

//   return (
//     <div className="relative text-white text-[20px] w-auto md:w-[30rem] lg:w-[40rem] object-cover">
//       <div
//         className="w-full h-[30rem] overflow-hidden"
//         onMouseEnter={handleMouseEnter}
//         onMouseLeave={handleMouseLeave}
//         onMouseMove={handleMouseMove}
//         style={{ minHeight: "25rem" }}
//       >
//         {mainImage && (
//           <img
//             src={mainImage}
//             alt="Product"
//             className="object-cover w-full h-full overflow-hidden cursor-pointer"
//             style={{
//               transform: `scale(${zoomLevel})`,
//               transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
//             }}
//           />
//         )}
//       </div>
//       <div className="flex mt-4 space-x-2">
//         {thumbImage?.map((image, index) => (
//           <img
//             key={index}
//             src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
//             alt={`Thumbnail ${index}`}
//             className={`w-20 h-20 object-cover cursor-pointer ${
//               selectedThumbIndex === index ? "border-2 border-[#FBDD01]" : ""
//             }`}
//             onClick={() => handleThumbnailClick(index)}
//           />
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProductDetails;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineMinus } from "react-icons/ai";
import { BsPlus } from "react-icons/bs";
import RelatedProduct from "../components/RelatedProduct";
import {
  addToSingleCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import SectionTitle from "../components/SectionTitle";

import { toast } from "react-toastify";

import { Parser } from "html-to-react";
import { Triangle } from "react-loader-spinner";
import { specificAttributeFetching } from "../features/products/specificAttributeSlice";
import { specificColorAttributeFetching } from "../features/products/specificColorAttributeSlice";
import { singleProductFetching } from "../features/products/singleProductSlice";
import { thumbImageFetching } from "../features/products/thumbImageSlice";

const ProductDetails = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { items: singleProduct, loader } = useSelector(
    (state) => state.singleProduct
  );

  const { items: thumbImage } = useSelector((state) => state.thumbImage);
  const { items: attribute } = useSelector((state) => state.attribute);
  const { items: colorAttribute } = useSelector(
    (state) => state.colorAttribute
  );
  const [cartQuantity, setCartQuantity] = useState(1);

  const [option, setOption] = useState(null);
  const [color, setColor] = useState(null);

  const setSelectedOption = (e) => {
    const option = attribute?.find(
      (option) => option?.variant === e.target.value
    );

    setOption(option);
  };

  const setColorOption = (e) => {
    const color = colorAttribute.find(
      (color) => color?.variant === e.target.value
    );

    setColor(color);
  };

  useEffect(() => {
    dispatch(specificAttributeFetching(id));
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(specificColorAttributeFetching(id));
  }, [dispatch, id]);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singleProductFetching(id));
    dispatch(thumbImageFetching(id));
  }, [dispatch, id]);

  const htmlParser = new Parser();

  const handleDecrease = useCallback(() => {
    setCartQuantity(cartQuantity === 1 ? 1 : (prev) => prev - 1);
    cartQuantity > 1 &&
      toast.warn("Quantity Decreased", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
  }, [cartQuantity]);

  const handleIncrease = useCallback(() => {
    setCartQuantity((prev) => prev + 1);
    toast.warn("Quantity Increased", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }, []);

  const handleAddToCart = (action) => {
    switch (action) {
      case "add_to_cart":
        if ((option && color) || (!option && color) || (option && !color)) {
          if (+singleProduct?.product?.current_stock >= cartQuantity) {
            dispatch(
              addToSingleCart({
                ...singleProduct.product,
                cartQuantity,
                colorAtr: color?.variant,
                sizeAtr: option?.variant,
                variantPrice:
                  singleProduct?.product?.discount > 0
                    ? +singleProduct?.product?.after_discount
                    : +singleProduct?.product?.unit_price,
              })
            );
          } else {
            toast.warn("Not Enough Product In Stock", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          toast.warn("Please Select Those Option", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        break;

      case "buy_now":
        if ((option && color) || (!option && color) || (option && !color)) {
          if (+singleProduct?.product?.current_stock >= cartQuantity) {
            dispatch(removeAllFromCart());
            dispatch(
              addToSingleCart({
                ...singleProduct.product,
                cartQuantity,
                colorAtr: color?.variant,
                sizeAtr: option?.variant,
                variantPrice:
                  singleProduct?.product?.discount > 0
                    ? +singleProduct?.product?.after_discount
                    : +singleProduct?.product?.unit_price,
              })
            );
            navigate("/checkout");
          } else {
            toast.warn("Not Enough Product In Stock", {
              position: "bottom-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        } else {
          toast.warn("Please Select Those Option", {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }

        break;
      default:
        break;
    }
  };

  return (
    <>
      {!loader ? (
        <div className="container mx-auto md:my-10 min-h-screen">
          <div className="flex flex-col lg:flex-row md:px-10 gap-[2rem] lg:gap-[3rem] p-3 items-center lg:items-start">
            <ProductImageCarasol
              thumbImage={thumbImage}
              singleProduct={`${process.env.REACT_APP_URL}/uploads/product/${singleProduct?.product?.photos}`}
            />

            {singleProduct?.status && (
              <div className="right flex lg:w-[40rem] w-full flex-col justify-start gap-3">
                <div className="flex  flex-col">
                  <form onSubmit={(e) => e.preventDefault()}>
                    <div className="details flex md:flex-row flex-col gap-8 text-md mt-[12px]">
                      <div className="flex flex-col gap-5">
                        <div className="md:text-4xl sm:text-3xl text-black font-semibold">
                          {singleProduct?.product?.name}
                        </div>
                        <div className="flex gap-5">
                          {singleProduct?.product && (
                            <div className="flex gap-5">
                              {+singleProduct?.product?.discount > 0 && (
                                <span className="font-semibold line-through text-[#FA5303] decoration-200">
                                  ৳ {singleProduct?.product?.unit_price}
                                </span>
                              )}
                              <div className="font-medium text-2xl">
                                {singleProduct?.product &&
                                singleProduct?.product?.discount > 0 ? (
                                  <>
                                    <span>
                                      ৳ {singleProduct?.product?.after_discount}
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    {singleProduct?.product && (
                                      <span>
                                        ৳
                                        {singleProduct?.product?.unit_price *
                                          cartQuantity}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          )}

                          {/* {option && (
                          <div className="flex gap-5">
                            {+option?.discount_price > 0 && (
                              <span className="font-semibold line-through text-[#FA5303] decoration-200">
                                ৳ {option?.price}
                              </span>
                            )}
                            <div className="font-medium text-2xl">
                              {option && +option?.discount_price > 0 ? (
                                <>
                                  <span>৳ {option?.discount_price}</span>
                                </>
                              ) : (
                                <>
                                  {option && (
                                    <span>
                                      ৳ {option?.price * cartQuantity}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )} */}

                          {/* {color && (
                          <div className="flex gap-5">
                            {+color?.discount_price > 0 && (
                              <span className="font-semibold line-through text-[#FA5303] decoration-200">
                                ৳ {color?.price}
                              </span>
                            )}
                            <div className="font-medium text-2xl">
                              {color && +color?.discount_price > 0 ? (
                                <>
                                  <span>৳ {color?.discount_price}</span>
                                </>
                              ) : (
                                <>
                                  {color && (
                                    <span>৳ {color?.price * cartQuantity}</span>
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                        )} */}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col gap-5 my-5">
                      {attribute.length > 0 && (
                        <div>
                          <div className="font-semibold text-xl mb-3">
                            Choose Size
                          </div>
                          <div className="flex flex-wrap gap-3">
                            {attribute.map((attr) => (
                              <label
                                key={attr.variant}
                                className="flex items-center gap-2 p-2 border border-gray-300 rounded-md cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="attribute"
                                  value={attr.variant}
                                  onChange={setSelectedOption}
                                  required
                                  className="form-radio h-5 w-5 text-[#FA5303] focus:ring-0"
                                />
                                {attr.variant}
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                      {colorAttribute.length > 0 && (
                        <div>
                          <div className="font-semibold text-xl mb-3">
                            Choose Color
                          </div>
                          <div className="flex flex-wrap gap-3">
                            {colorAttribute.map((color) => (
                              <label
                                key={color.variant}
                                className="flex items-center gap-2 p-2 border border-gray-300 rounded-md cursor-pointer"
                              >
                                <input
                                  type="radio"
                                  name="color"
                                  value={color.variant}
                                  onChange={setColorOption}
                                  required
                                  className="form-radio h-5 w-5 text-[#FA5303] focus:ring-0"
                                />
                                {color.variant}
                              </label>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="cart-quantity flex items-center justify-start">
                        <div className="text-lg font-medium">Quantity:</div>
                        <div className="flex items-center ml-3">
                          <button
                            type="button"
                            className="w-8 h-8 flex items-center justify-center border border-gray-300 text-gray-500"
                            onClick={handleDecrease}
                          >
                            <AiOutlineMinus />
                          </button>
                          <div className="w-12 h-8 flex items-center justify-center border-t border-b border-gray-300">
                            {cartQuantity}
                          </div>
                          <button
                            type="button"
                            className="w-8 h-8 flex items-center justify-center border border-gray-300 text-gray-500"
                            onClick={handleIncrease}
                          >
                            <BsPlus />
                          </button>
                        </div>
                      </div>
                      <div className="total-price flex gap-3 items-center ">
                        <span className="inline-block text-slate-600/50 text-[0.9rem] font-medium uppercase items-center">
                          Total Stock :
                        </span>
                        <span className="inline-block text-2xl font-semibold text-[#FBDD01] ">
                          {singleProduct?.product?.current_stock}
                        </span>
                      </div>
                      {singleProduct?.product?.current_stock?.length > 0 ? (
                        <p className="text-3xl text-red-400">Out Of Stock</p>
                      ) : (
                        <div className="flex mt-8 items-center flex-wrap lg:flex-nowrap font-semibold gap-5">
                          <button
                            onClick={() => handleAddToCart("add_to_cart")}
                            // disabled={option === null}
                            className={`border-[1px] ${
                              option === null ? "" : ""
                            } border-[#FBDD01] text-[#000] flex justify-center items-center  hover:bg-[#FBDD01] hover:text-white duration-300 whitespace-nowrap w-full py-1 px-36 lg:px-28 rounded-full`}
                            type="submit"
                          >
                            Add to Cart
                          </button>

                          <button
                            onClick={() => handleAddToCart("buy_now")}
                            className="border flex justify-center items-center bg-[#FBDD01]  text-black duration-300 whitespace-nowrap w-full px-36 lg:px-28 rounded-full py-1 "
                            type="submit"
                          >
                            Buy Now
                          </button>
                        </div>
                      )}
                    </div>
                  </form>
                  {singleProduct?.product?.video_link ? (
                    <div className="overflow-hidden mt-5 w-full  flex items-center justify-center">
                      <iframe
                        width="300"
                        height="215"
                        src={`https://www.youtube.com/embed/${
                          singleProduct?.product?.video_link?.split("=")[1]
                        }`}
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                      ></iframe>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="px-3 mb-20 md:mb-15  md:px-0">
            <SectionTitle title={"Description"} />
            <div className="mb-10">
              {singleProduct?.product?.description ? (
                htmlParser.parse(singleProduct?.product?.description)
              ) : (
                <p>No Description Added</p>
              )}
            </div>

            <RelatedProduct singleProduct={singleProduct} />
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-[calc(100vh-20rem)]">
          <Triangle height="80" width="80" color="#FBDD01" />
        </div>
      )}
    </>
  );
};

export const ProductImageCarasol = ({ thumbImage, singleProduct }) => {
  const [mainImage, setMainImage] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0 });
  const [selectedThumbIndex, setSelectedThumbIndex] = useState(0);
  const thumbnailContainerRef = useRef(null);

  useEffect(() => {
    if (singleProduct) {
      setMainImage(singleProduct);
    }
  }, [singleProduct]);

  const handleMouseEnter = () => {
    setZoomLevel(2);
  };

  const handleMouseLeave = () => {
    setZoomLevel(1);
    setZoomPosition({ x: 0, y: 0 });
  };

  const handleMouseMove = (e) => {
    const { left, top, width, height } =
      e.currentTarget.getBoundingClientRect();
    const x = ((e.clientX - left) / width) * 100;
    const y = ((e.clientY - top) / height) * 100;
    setZoomPosition({ x, y });
  };

  const handleThumbnailClick = (index) => {
    setSelectedThumbIndex(index);
    setMainImage(
      `${process.env.REACT_APP_URL}/uploads/product/${thumbImage[index]?.icon}`
    );
  };

  const scrollThumbnails = (direction) => {
    const container = thumbnailContainerRef.current;
    if (direction === "left") {
      container.scrollBy({ left: -100, behavior: "smooth" });
    } else if (direction === "right") {
      container.scrollBy({ left: 100, behavior: "smooth" });
    }
  };

  return (
    <div className="relative text-white text-[20px] w-auto md:w-[30rem] lg:w-[40rem] object-cover">
      <div
        className="w-full h-[20rem] md:h-[30rem] overflow-hidden"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      >
        {mainImage && (
          <img
            src={mainImage}
            alt="Product"
            className="object-cover w-full h-full overflow-hidden cursor-pointer"
            style={{
              transform: `scale(${zoomLevel})`,
              transformOrigin: `${zoomPosition.x}% ${zoomPosition.y}%`,
            }}
          />
        )}
      </div>
      <div className="flex mt-4 space-x-2 items-center">
        <button
          className="bg-gray-300 text-black p-2 rounded-full"
          onClick={() => scrollThumbnails("left")}
        >
          {"<"}
        </button>
        <div
          ref={thumbnailContainerRef}
          className="flex overflow-x-scroll space-x-2 w-[calc(100%-4rem)] scrollbar-hide"
        >
          {thumbImage.map((image, index) => (
            <img
              key={index}
              src={`${process.env.REACT_APP_URL}/uploads/product/${image?.icon}`}
              alt={`Thumbnail ${index}`}
              className={`w-[85px] h-[85px] object-cover cursor-pointer ${
                selectedThumbIndex === index ? "border-2 border-blue-500" : ""
              }`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
        <button
          className="bg-gray-300 text-black p-2 rounded-full"
          onClick={() => scrollThumbnails("right")}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default ProductDetails;
