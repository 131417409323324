import { useParams } from "react-router-dom";

import { useEffect, useState } from "react";
import Card from "../components/Card";
import { ClipLoader } from "react-spinners";

const CategoryPage = () => {
  const [categoryProduct, setCategoryProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const res = await fetch(
          `${process.env.REACT_APP_URL}/api-categories/${id}`
        );
        const data = await res.json();
        setLoading(false);
        setCategoryProduct(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchData();
  }, [id]);

  return (
    <div className=" px-3 container mx-auto my-10 ">
      {loading ? (
        <div className="flex items-center justify-center min-h-[calc(100vh-300px)]">
          <ClipLoader
            visible={true}
            height="120"
            width="120"
            color="#D89307"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : categoryProduct?.length > 0 ? (
        <>
          <div className="grid  grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5  gap-5">
            {categoryProduct?.map((product) => (
              <Card key={product.id} product={product} />
            ))}
          </div>
        </>
      ) : (
        <p className="text-center text-rose-500 text-xl">No Products Found</p>
      )}
    </div>
  );
};

export default CategoryPage;
