import { ToastContainer } from "react-toastify";
import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";

import MobileNav from "../components/MobileNav";
import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import CartButton from "../components/CardButton";
import AddToCartCom from "../components/AddToCartCom";
import { useState } from "react";
import User from "../pages/user/User";

const UserLayout = () => {
  const { cartItems: data, cartTotalAmount } = useSelector(
    (state) => state.cart
  );
  const [state, setState] = useState(false);
  const handleAddToCart = () => {
    setState(!state);
  };
  return (
    <>
      <div className="min-h-screen bg-[#F3F4F6] relative ">
        <Navbar />
        <ToastContainer />
        <ScrollToTop>
          <User />
        </ScrollToTop>

        <button
          onClick={handleAddToCart}
          className="fixed top-[45%] right-0 z-[9999]"
        >
          <CartButton data={data} cartTotalAmount={cartTotalAmount} />
        </button>
        {state && <AddToCartCom state={state} setState={setState} />}
        <div className="fixed z-[99999] bottom-0  md:hidden block w-full ">
          <MobileNav />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserLayout;
