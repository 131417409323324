import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaYoutube,
  FaTiktok,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  const { items: data } = useSelector((state) => state.category);
  const { items: footerData } = useSelector((state) => state.footerDetails);
  const { items: logo } = useSelector((state) => state.logo);

  const { items: adminInfo } = useSelector((state) => state.information);

  const navActive = ({ isActive }) => {
    return {
      color: isActive ? "#9A2A55" : null,
    };
  };
  return (
    <div className="bg-[#FBDD01] text-[#141101] mb-10 md:mb-0 md:px-10 md:pt-8 pb-12 p-4">
      <div className="container mx-auto">
        <div className="gap-5 flex flex-col lg:flex-row justify-between">
          <div className="flex flex-col gap-4">
            <span className="font-light text-sm"></span>
            <Link to={"/"} className="w-20 h-20 rounded-full overflow-hidden">
              <img
                src={`${process.env.REACT_APP_URL}/backend/assets/jpg/${logo?.avatar}`}
                className="max-h-full max-w-full w-auto  h-auto object-cover "
                alt={`${logo?.name}`}
              />
            </Link>

            <h2 className="text-md mb-4 font-light">
              Subscribe to Our Newsletter
            </h2>
            <form>
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2 mb-4">
                <label className="sr-only" htmlFor="email">
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email"
                  className="bg-slate-50 rounded-lg py-2 px-4 text-[#141101] w-[15rem]"
                />
                <button
                  type="submit"
                  className="bg-black text-white rounded-lg py-2 px-4 ml-2"
                >
                  Subscribe
                </button>
              </div>
            </form>
            <div className="flex  items-center gap-4">
              <h2 className="text-md font-light">Follow Us</h2>
              <div className="flex items-center">
                <Link
                  // to={`https://www.facebook.com/MehendiByMimi`}
                  // href="https://www.facebook.com/MehendiByMimi"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#141101] hover:text-blue-400 mr-4"
                >
                  <FaFacebook />
                </Link>
                <Link
                  // to={`https://www.tiktok.com/@mehendibymimi28`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#141101] hover:hover:text-blue-400 mr-4"
                >
                  <FaTwitter />
                </Link>
                <Link
                  // to={`https://www.youtube.com/@mehendibymimi`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#141101] hover:hover:text-blue-400 mr-4"
                >
                  <FaYoutube />
                </Link>

                <Link
                  // to={`https://www.instagram.com/mehendi_by_mimi/`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#141101] hover:text-red-400 mr-4"
                >
                  <FaInstagram />
                </Link>
                {/*  */}
                <Link
                  // to={`https://www.tiktok.com/@mehendibymimi28`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-[#141101] hover:text-red-400 mr-4"
                >
                  <FaTiktok />
                </Link>
              </div>
            </div>
          </div>

          <div className=" flex flex-col sm:flex-row items-start mt-4 md:mt-0 gap-10">
            <div className="flex flex-col ">
              <h2 className="text-md mb-2 text-[#141101] ">Useful Links</h2>
              <NavLink
                style={navActive}
                to={"/"}
                end
                className=" inline-block text-sm font-light text-[#141101] "
              >
                Home
              </NavLink>
              {data?.slice(0, 4).map((navItem) => (
                <NavLink
                  style={navActive}
                  to={`/categorypage/category/${navItem.id}`}
                  key={navItem.id}
                  className="text-[#141101] text-sm  font-light"
                >
                  {navItem.name}
                </NavLink>
              ))}
              <Link
                to="terms"
                className=" inline-block text-sm font-light text-[#141101] "
              >
                Terms & conditions
              </Link>

              <Link
                to="returnpolicy"
                className=" inline-block text-sm font-light text-[#141101]"
              >
                Return policy
              </Link>
              <Link
                to="supportpolicy"
                className=" inline-block text-sm font-light text-[#141101] NavLink
            "
              >
                Support Policy
              </Link>
              <Link
                className=" inline-block text-sm font-light text-[#141101] NavLink
            "
                to="privacypolicy"
              >
                Privacy policy
              </Link>
            </div>
            <div className="flex flex-col ">
              <h2 className="text-md mb-2 text-[#141101] ">Company</h2>

              {footerData?.slice(0, 5).map((navItem) => (
                <NavLink
                  style={navActive}
                  to={`/${navItem.slug}/${navItem.id}`}
                  key={navItem.id}
                  className="text-[#141101]text-sm  font-light"
                >
                  {navItem.title}
                </NavLink>
              ))}

              {/* <Link
                to={"/mehendi-review/53"}
                className=" inline-block text-sm font-light text-slate-50"
              >
                Review
              </Link> */}
              <Link
                to={"/sellcondition"}
                className=" inline-block text-sm font-light text-[#141101]"
              >
                ক্রয় বিক্রয় ও অন্যান্য শর্তাবলী
              </Link>
            </div>
            <div className="flex flex-col ">
              <h2 className="text-md mb-2 text-[#141101]">Contact info</h2>
              <Link className=" inline-block text-sm font-light text-[#141101] ">
                {adminInfo.address}
              </Link>

              <Link className=" inline-block text-sm font-light text-[#141101]">
                {adminInfo.phone}
              </Link>
              <Link
                className=" inline-block text-sm font-light text-slat NavLink
            "
              >
                {adminInfo.email}
              </Link>
            </div>
          </div>
        </div>
        <footer className="footer flex items-center justify-between px-0   gap-5  text-[#141101] ">
          <div className="flex justify-center">
            <p className="text-[#141101] text-sm font-light mt-5">
              &copy; {new Date().getFullYear()} Bornbd. All rights reserved.
            </p>
          </div>
          <div className="image-container w-60 ">
            <img
              src="https://old.mehendibymimi.com/public/uploads/all/lnfIO3PdB1OE45OgRLmHu8fZdLRselIYRdKpAyam.png"
              alt="paymentimage"
            />
          </div>

          <a
            href="https://softcreation.com.bd/"
            rel="noreferrer"
            target="_blank"
          >
            <span>
              Developed by :
              <span className="text-blue-500">Soft Creation Limited</span>
            </span>
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Footer;
